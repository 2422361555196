import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Masonry from "react-masonry-css"
import Button from "../button/Button"

import { DialogOverlay, DialogContent } from "@reach/dialog"
import "@reach/dialog/styles.css"

import * as styles from "./PhotoAlbum.module.css"


const PhotoAlbum = ({ photos }) => {
    const [toggle, setToggle] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1
    }

    return (
        <React.Fragment>
           <Masonry
                breakpointCols={breakpointColumnsObj}
                className={styles.wrapper}
                columnClassName={styles.column}
            >

            {console.log(photos)}


            {photos.map((photo, i) => (
                <button
                    key={i}
                    className={styles.button}
                    onClick={() => {
                        setToggle(true)
                        setSelectedImage(photo.node)
                    }}
                >
                    <GatsbyImage
                        image={getImage(photo.node)}
                        alt=""
                        className={styles.photo}
                    />
                </button>
            ))}
            </Masonry>

            
            {toggle && (
                <DialogOverlay
                    onDismiss={() => setToggle(false)}
                    className={styles.dialogOverlay}
                >
                    <DialogContent
                        className={styles.dialogContent}
                        aria-label="Selected image"
                    >
                        <GatsbyImage
                            image={getImage(selectedImage)}
                            alt=""
                            className={styles.photo}
                        />
                        <Button
                            className={styles.close}
                            onClick={() => setToggle(false)}
                        >
                            Close
                        </Button>
                    </DialogContent>
                </DialogOverlay>
            )}
        </React.Fragment>
    )
}
        
export default PhotoAlbum