import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"


import Layout from "../templates/Layout"

import PhotoAlbum from "../components/photo-album/PhotoAlbum"


export const pageQuery = graphql`
    query Gallery {
        mdx(slug: {eq: "gallery"}) {
            body
            frontmatter {
              title
              summary
            }
            body
        },
        allFile(filter: {relativeDirectory: {eq: "gallery"}}) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData (
                            transformOptions: {
                                cropFocus: ATTENTION
                            }
                        )
                    }
                }
            }
        }
    }
`


class Gallery extends React.Component {

    render() {
        const data = this.props.data.mdx
        const gallery = this.props.data.allFile.edges

        return (
            <Layout
                title={data.frontmatter.title}
                location={this.props.location}
            >
                <h1>{data.frontmatter.title}</h1>
                <p>{data.frontmatter.summary}</p>
                <MDXRenderer>{data.body}</MDXRenderer>

                <PhotoAlbum
                    photos={gallery}
                />
            </Layout>
        )
    }
}
        
export default Gallery